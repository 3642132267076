<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-18 09:47:13
 * @LastEditTime: 2020-12-23 22:13:16
-->
<template>
  <div>
    <van-field
      v-model="imgLength"
      :label="label"
      :required="required"
      :rules="[{ required: true, message: `${label}不能为空` }]"
      class="u-image-upload"
    >
      <template #button>
        <div class="button" @click="chooseImage">
          {{ buttonText }}
        </div>
      </template>
    </van-field>
    <div class="imgList" v-if="imgList.length">
      <div class="img-item" v-for="(img, i) in imgList" :key="i">
        <img v-lazy="img" @click="showPreview(i)" />
        <van-icon name="cross" @click="removeImg(i)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "",
  props: {
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      mapCount: null,
      imgLength: "",
      imgList: [
        // {
        //   src: "https://img.yzcdn.cn/vant/apple-1.jpg",
        //   serverId: 1,
        // },
        // {
        //   src: "https://img.yzcdn.cn/vant/apple-1.jpg",
        //   serverId: 2,
        // },
      ]
    };
  },
  mounted() {
    this.mapCount = this.$tools.getJson(this.max);
  },
  model: {
    prop: "ids",
    event: "returnBack"
  },
  watch: {
    imgList(val) {
      if (val.length) {
        this.imgLength = val.length;
      } else {
        this.imgLength = "";
      }
      this.$emit(
        "returnBack",
        val.map(item => item.serverId)
      );
    }
  },
  methods: {
    // 选择图片
    chooseImage() {
      this.$wx.chooseImage({
        count: this.maxCount - this.imgList.length, //可选照片数量
        sizeType: ["compressed"], //可以指定是原图还是压缩图，默认二者都有
        sourceType: ["camera", "album"], //从相册选择
        success: res => {
          // console.log(res);
          // console.log(1);
          this.syncImgData(res.localIds);
        },
        fail: err => {
          console.log("failChoose", err);
        }
      });
    },
    // 本地图片显示转换base64
    syncImgData(localIds) {
      var localId = localIds.shift();
      var that = this;
      this.$wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (resBackData) {
          var localData = resBackData.localData;
          let imageBase64 = "";
          // 这里处理的是ios和安卓的区别
          if (localData.indexOf("data:image") == 0) {
            imageBase64 = localData;
          } else {
            imageBase64 =
              "data:image/jpeg;base64," + localData.replace(/\n/g, "");
          }
          that.$tools.compressImage(imageBase64, {}, data => {
            // 图片压缩
            that.uploadImageToWXServer(localId, data);
          });
          if (localIds.length > 0) {
            that.syncImgData(localIds);
          }
        }
      });
    },
    // 图片上传
    uploadImageToWXServer(localId, data) {
      var that = this;
      this.$wx.uploadImage({
        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          that.imgList.push({
            src: data,
            serverId
          });
        }
      });
    },
    // 图片预览
    showPreview(i) {
      ImagePreview({
        images: this.imgList.map(img => img.src),
        startPosition: i,
        closeable: true
      });
    },
    // 图片移除
    removeImg(i) {
      this.imgList.splice(i, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.u-image-upload {
  /deep/.van-field__control {
    opacity: 0;
  }
}
.button {
  height: 0.8rem /* 15/18.75 */;
  line-height: 0.8rem /* 15/18.75 */;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2b71fd;
}
.imgList {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0.8rem /* 15/18.75 */ 1.066667rem /* 20/18.75 */;
  .img-item {
    width: 2.666667rem /* 50/18.75 */;
    height: 2.666667rem /* 50/18.75 */;
    margin-right: 1.173333rem /* 22/18.75 */;
    border-radius: 0.266667rem /* 5/18.75 */;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .van-icon-cross {
      position: absolute;
      top: -0.32rem /* 6/18.75 */;
      right: -0.32rem /* 6/18.75 */;
      border-radius: 50%;
      background: red;
      color: #fff;
      font-size: 0.64rem /* 12/18.75 */;
    }
  }
}
</style>

<template>
  <div class="shop-check-in h-100 bg-gray">
    <van-form ref="formData"
              class="u-form">
      <van-field v-model="formData.spzt"
                 required
                 readonly
                 clickable
                 label="商家主体"
                 placeholder="请选择商家主体"
                 right-icon="arrow"
                 :rules="[{ required: true,message:'请选择商家主体'}]"
                 @click="showPicker = true;pickType='spzt'"></van-field>
      <van-field required
                 clearable
                 v-model="formData.spmc"
                 label="商家名称"
                 placeholder="请输入商家名称"
                 :rules="[{ required: true, message: '请输入商家名称' }]" />
      <van-field v-model="sjdz"
                 required
                 readonly
                 clickable
                 label="商家地址"
                 placeholder="请选择商家地址"
                 :rules="[{ required: true, message: '请选择商家地址' }]"
                 @click="showMap=true"
                 right-icon="arrow"></van-field>
      <van-field right-icon="arrow"
                 required
                 readonly
                 clickable
                 label="省市区"
                 v-model="formData.region"
                 :rules="[{ required: true, message: '请选择省市区' }]"
                 placeholder="点击选择省市区"
                 @click="showAreaPicker = true" />
      <van-field right-icon="arrow"
                 required
                 readonly
                 clickable
                 label="乡镇街道"
                 v-model="formData.street"
                 :rules="[{ required: true, message: '请选择街道信息' }]"
                 placeholder="点击选择街道信息"
                 @click="showPicker = true ; pickType='street'" />
      <van-field right-icon="arrow"
                 readonly
                 clickable
                 label="村社区"
                 v-model="formData.community"
                 placeholder="点击选择村社区信息"
                 @click="showPicker = true ; pickType='community'" />
      <van-field required
                 clearable
                 v-model="formData.address"
                 autosize
                 label="详细地址"
                 placeholder="请输入详细地址"
                 :rules="[{ required: true, message: '请输入详细地址' }]" />
      <van-field style="margin-top: 6px"
                 v-model="formData.splxName"
                 required
                 readonly
                 clickable
                 label="商铺类型"
                 placeholder="请选择商铺类型"
                 right-icon="arrow"
                 :rules="[{ required: true, message: '请选择商铺类型' }]"
                 @click="showPicker = true ; pickType='splx'"></van-field>
      <van-field required
                 v-model="formData.spjs"
                 label="商家介绍"
                 placeholder="请输入商家介绍"
                 :rules="[{ required: true, message: '请输入商家介绍' }]" />
      <van-field required
                 v-model="formData.gddh"
                 label="商家电话  "
                 placeholder="请输入商家电话"
                 :rules="[{ required: true, validator:gddhValidator, message: '请输入正确商家电话',trigger :'onBlur' }]" />
      <u-image-upload label="商家照片"
                      v-model="formData.spzps"
                      buttonText="上传商家照片"
                      required></u-image-upload>
      <u-image-upload style="margin-top: 6px"
                      label="营业执照"
                      v-if="formData.spzt==='企业'"
                      v-model="formData.yyzzs"
                      buttonText="上传营业执照"
                      required></u-image-upload>
      <u-button size="large"
                :disabled="disabled||sending"
                style="margin-top: 55px;margin-bottom:25px"
                type="primary"
                @click="submit">提交</u-button>
    </van-form>
    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  ref="picker"
                  :title="pickerTitle"
                  :columns="columns[this.pickType]"
                  @confirm="onConfirm"
                  @cancel="showPicker = false" />
    </van-popup>
    <van-popup v-model="showAreaPicker"
               round
               position="bottom">
      <van-area title="省市区"
                :area-list="areaList"
                @confirm="onAreaConfirm"
                @cancel="showAreaPicker = false" />
    </van-popup>
    <choose-map v-if="showMap"
                @choose="mapChoose"></choose-map>
  </div>
</template>
<script>
import UButton from '@/components/u-button';
import UImageUpload from '@/components/u-image-upload';
import ChooseMap from '@/components/choose-map';
import api from '@api'
export default {
  components: { UButton, UImageUpload, ChooseMap },
  data () {
    return {
      showMap: false,
      showAreaPicker: false,
      showPicker: false,
      pickType: '',
      columns: {
        spzt: [{ text: '个人', value: 0 }, { text: '企业', value: 1 }],
        street: [],
        community: [],
        splx: []
      },
      areaList: {
        province_list: {
        },
        city_list: {
        },
        county_list: {
        }
      },
      sjdz: '',
      formData: {
        spzt: '',
        spztCode: 0,
        spmc: "", //商家名称
        region: '', //省市县 
        street: "", //乡镇街道
        community: "", //社区
        address: "", //详细地址
        splxName: '', // 商铺类型
        spjs: "", // 商家介绍
        gddh: "", //商家电话
      },
      sending: false
    };
  },
  mounted () {
    this.$globalFun.checkUserState() // 提现实名
    this.getsplxData()
    this.getAreaList();
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    }
    // this.$wx.ready(() => {
    //   this.$wx.getLocation({
    //     type: 'wgs84',
    //     success: (res) => {
    //       this.formData.spjd = res.latitude
    //       this.formData.spwd = res.longitude
    //       this.getDistrict(res.latitude, res.longitude);
    //     },
    //   })
    // })
  },
  computed: {
    disabled () {
      let requireds = ['spzt', 'spmc', 'region', 'street', 'address', 'splxName', 'spjs', 'gddh']
      let disabled = false
      requireds.forEach(required => {
        if (!this.formData[required]) {
          disabled = true
        }
      })
      return disabled
    },
    pickerTitle () {
      let pickerTitle = "";
      switch (this.pickType) {
        case "spzt":
          pickerTitle = "商铺主体";
          break;
        case 'street':
          pickerTitle = '街道信息'
          break;
        case 'community':
          pickerTitle = '社区信息'
          break;
        case "splb":
          pickerTitle = "商铺类别";
          break;
        case 'splx':
          pickerTitle = '商铺类型';
          break;
      }
      return pickerTitle
    }
  },
  methods: {
    // 获取定位详址
    getDistrict (lat, lon) {
      let data = {
        location: `${lat},${lon}`,
        key: 'ILYBZ-7ZMK2-RPMU3-CSPUT-62L5T-LNFX5',
        output: 'jsonp'
      }
      this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1`, data).then(res => {
        if (!res.status) {
          let data = res.result
          this.sjdz = data.address
          if (!this.$route.query.id) {
            let info = data.ad_info
            this.formData.region = info.province + '/' + info.city + '/' + info.district
            this.formData.regionCode = info.adcode
            this.getStreetList(info.adcode)
          }
        }
      }).catch(err => {
      })
    },
    // 获取审核不通过的商铺详情
    getDetail (id) {
      api.initBusiness({ id }).then(res => {
        if (res.code === 'SUCCESS') {
          let data = res.data
          this.formData.spzt = data.spzt ? '企业' : '个人'
          this.formData.spztCode = data.spzt
          this.formData.spmc = data.spmc
          this.formData.region = data.region
          this.formData.regionCode = data.regionCode
          this.formData.street = data.street
          this.formData.streetCode = data.streetCode
          this.formData.community = data.community
          this.formData.communityCode = data.communityCode
          this.formData.address = data.address
          this.formData.splb = data.splb
          this.formData.splbName = data.splbName
          this.formData.splx = data.splx
          this.formData.splxName = data.splxName
          this.formData.spjs = data.spjs
          this.formData.gddh = data.gddh
          this.getStreetList(data.regionCode)
          this.getCommunityList(data.streetCode)
        }
      })
    },
    // 获取商品类型
    getsplxData () {
      api.getsplxData().then(res => {
        this.columns.splx = res.data.map(item => {
          return {
            text: item.name,
            value: item.code,
            children: item.dictLibraryPOList.map(it => {
              return {
                text: it.name,
                value: it.code,
              }
            })
          }
        })
      })
    },
    // 获取行政区划的列表
    getAreaList () {
      api.getAreaList().then((res) => {
        res.data.forEach((province) => {
          this.areaList.province_list[province.provinceCode] =
            province.provinceName;
          province.cityList.forEach((city) => {
            this.areaList.city_list[city.cityCode] = city.cityName;
            city.areaList.forEach((area) => {
              this.areaList.county_list[area.areaCode] = area.areaName;
            });
          });
        });
      });
    },
    // 省市县弹窗确定
    onAreaConfirm (areas) {
      if (areas) {
        let region = areas[0].name + '/' + areas[1].name + '/' + areas[2].name
        this.formData.region = region
        this.formData.regionCode = areas[2].code
        // 重置街道以及社区信息以及小区信息
        this.columns.street = []
        this.formData.street = ''
        this.formData.streetCode = ''
        this.columns.community = []
        this.formData.community = ''
        this.formData.communityCode = ''
        // 获取街道信息
        this.getStreetList(areas[2].code)
      }
      this.showAreaPicker = false
    },
    // 获取街道列表
    getStreetList (areaCode) {
      api.getStreetList(areaCode).then((res) => {
        this.columns.street = res.data.map((item) => {
          return {
            value: item.townCode,
            text: item.townName
          }
        })
      })
    },
    // 获取社区列表
    getCommunityList (townCode) {
      api.getCommunityList(townCode).then((res) => {
        this.columns.community = res.data.map((item) => {
          return {
            value: item.villageCode,
            text: item.villageName,
          };
        });
      });
    },
    // 获取地址
    mapChoose (data) {
      this.formData.spjd = data.lat
      this.formData.spwd = data.lng
      this.sjdz = data.addr
      this.showMap = false
    },
    // 弹窗确定
    onConfirm (value) {
      if (!value) {
      } else if (value.length) { // 商铺类型
        this.formData.splbName = value[0]
        this.formData.splxName = value[1]
        this.columns.splx.forEach(splb => {
          if (splb.text === value[0]) {
            this.formData.splb = splb.value
            splb.children.forEach(splx => {
              if (splx.text === value[1]) {
                this.formData.splx = splx.value
              }
            })
          }
        })
      } else if (value) {
        this.formData[this.pickType] = value.text;
        value.value && (this.formData[this.pickType + 'Code'] = value.value)
        if (this.pickType === 'street') {
          // 重置社区信息
          this.columns.community = []
          this.formData.community = ''
          this.formData.communityCode = ''
          // 获取社区信息
          this.getCommunityList(value.value)
        } else if (this.pickType === 'community') {
        }
      }
      this.showPicker = false;
    },
    // 商家电话验证
    gddhValidator (val) {
      console.log(this.$tools.isPhone(val) || this.$tools.isTel)
      return this.$tools.isPhone(val) || this.$tools.isTel(val)
    },
    // 提交
    submit () {
      if (this.$globalFun.checkUserState()) {
        this.$refs.formData.validate().then(res => {
          let formData = this.$tools.getJson(this.formData)
          formData.spzt = formData.spztCode
          delete formData.spztCode
          this.$dialog.confirm({
            title: '提示',
            message: '确认提交',
          })
            .then(() => {
              this.sending = true
              api.createBusiness(formData).then(res => {
                this.sending = false
                if (res.code === 'SUCCESS') {
                  this.$router.go(-1)
                }
              })
            })
            .catch(() => {
            });
        })
      }
    },
  }
}
</script>

<style lang="less" scope>
.shop-check-in {
  position: relative;
}
.van-form {
  height: 100%;
  overflow-y: auto;
}
</style>

<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-18 14:07:50
 * @LastEditTime: 2020-12-18 14:29:31
-->
<template>
  <div class="choose-map">
    <iframe src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ILYBZ-7ZMK2-RPMU3-CSPUT-62L5T-LNFX5&referer=hydd"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    window.addEventListener('message', (event) => {
      console.log('event: ', event);
      let loc = event.data;
      if (loc && loc.module == 'locationPicker') {
        let locationData = {
          name: loc.poiname,
          addr: loc.poiaddress,
          lat: loc.latlng.lat,
          lng: loc.latlng.lng,
          city: loc.cityname,
          module: loc.module,
        }
        this.$emit('choose', locationData)
      }
    }, false);
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.choose-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
